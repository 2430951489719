import { sourcesProvideIndex } from '@outmind/helpers';
import { DocumentIndicesExtended } from '@outmind/types';
import {
  Board as BoardFilled,
  BookContacts as BookContactsFilled,
  Briefcase as BriefcaseFilled,
  BuildingFactory as BuildingFactoryFilled,
  BuildingMultiple as BuildingMultipleFilled,
  Chat as ChatFilled,
  Document as DocumentFilled,
  Lightbulb as LightbulbFilled,
  Mail as MailFilled,
  OfficeApps as OfficeAppsFilled,
  Person as PersonFilled,
  SquareMultiple as SquareMultipleFilled,
  Wrench as WrenchFilled,
} from '@styled-icons/fluentui-system-filled';
import {
  Board,
  BookContacts,
  Briefcase,
  BuildingFactory,
  BuildingMultiple,
  Chat,
  Document,
  Lightbulb,
  Mail,
  OfficeApps,
  Person,
  SquareMultiple,
  Wrench,
} from '@styled-icons/fluentui-system-regular';
import classnames from 'classnames';
import React from 'react';

import { indexOrder } from '../../config';
import { useAllSearch, useConnectors, useDimensions, useTranslations } from '../../hooks';
import { TranslationKey } from '../../locales/types';
import { Tab, Tabs } from '../../material';
import { Actions, useDispatch, useSelector } from '../../store';
import { useStyles } from './styles';

/**
 * Minimal size empirically determined for one `Tab` to be fully displayed
 */
const MIN_SIZE_PER_TAB = 155;

export const IndexTabs: React.FC<IndexTabsProps> = ({ resetScroll }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [tabsRef, tabsDimensions] = useDimensions<HTMLButtonElement>();

  const { chosenIndexTab } = useSelector((s) => s.search);

  const allSearchResponses = useAllSearch();

  const { data: connectors = [] } = useConnectors();

  const sources = connectors.map((connector) => connector.source);

  const tabIconsProps = { fill: 'inherit', size: 20 };

  const dispatch = useDispatch();

  const onChangeTypeTab = (
    _: React.ChangeEvent<unknown>,
    typeTab: DocumentIndicesExtended,
  ): void => {
    resetScroll();
    dispatch(Actions.setChosenIndexTab(typeTab));
  };

  const indexTabIconMap: Record<
    DocumentIndicesExtended,
    { filledIcon: JSX.Element; icon: JSX.Element; selectedClass: string }
  > = {
    '*': {
      filledIcon: <OfficeAppsFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <OfficeApps className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabAllIcon,
    },
    accounts: {
      filledIcon: <BuildingMultipleFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <BuildingMultiple className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabAccountIcon,
    },
    cards: {
      filledIcon: <BoardFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Board className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabCardIcon,
    },
    construction_sites: {
      filledIcon: <BuildingFactoryFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <BuildingFactory className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabConstructionSitesIcon,
    },
    construction_tools: {
      filledIcon: <WrenchFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Wrench className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabConstructionToolsIcon,
    },
    contacts: {
      filledIcon: <BookContactsFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <BookContacts className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabContactIcon,
    },
    files: {
      filledIcon: <DocumentFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Document className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabFileIcon,
    },
    mails: {
      filledIcon: <MailFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Mail className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabMailIcon,
    },
    messages: {
      filledIcon: <ChatFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Chat className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabMessageIcon,
    },
    missions: {
      filledIcon: <BriefcaseFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Briefcase className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabMissionIcon,
    },
    opportunities: {
      filledIcon: <LightbulbFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Lightbulb className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabOpportunityIcon,
    },
    pages: {
      filledIcon: <SquareMultipleFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <SquareMultiple className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabPageIcon,
    },
    people: {
      filledIcon: <PersonFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <Person className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabPersonIcon,
    },
    /**
     * @deprecated
     */
    slides: {
      filledIcon: <SquareMultipleFilled className={classes.indexTabIcon} {...tabIconsProps} />,
      icon: <SquareMultiple className={classes.indexTabIcon} {...tabIconsProps} />,
      selectedClass: classes.indexTabPageIcon,
    },
  };

  const displayedIndices = indexOrder.filter((indexTab) => {
    if (indexTab === '*' || sourcesProvideIndex(sources, indexTab)) return true;
    return false;
  });

  if (displayedIndices.length === 1) return null;

  const withAllLabels = (tabsDimensions?.width || 0) / displayedIndices.length > MIN_SIZE_PER_TAB;

  const indexTabs = displayedIndices.map((indexTab) => {
    const indiceFR = t(`index_name_${indexTab}` as TranslationKey);

    let resultsTotalLabel = ' ';
    if (indexTab !== '*') {
      const { isLoading, total } = allSearchResponses[indexTab];
      resultsTotalLabel = isLoading ? '...' : ` ${total >= 10000 ? '10k+' : total}`;
    }

    const searchLabel = indexTab === chosenIndexTab || withAllLabels ? indiceFR : undefined;

    const label = (
      <>
        <span>{searchLabel || ''}</span>
        <span className={searchLabel ? classes.searchTotalLabel : undefined}>
          {resultsTotalLabel}
        </span>
      </>
    );

    return (
      <Tab
        key={indexTab}
        classes={{
          labelIcon: classes.indexTabRoot,
          root: classes.indexTabLabelContainer,
          selected: indexTabIconMap[indexTab].selectedClass,
          textColorInherit: classes.textColorInherit,
          wrapper: classes.indexTabWrapper,
        }}
        disableRipple
        icon={
          chosenIndexTab === indexTab
            ? indexTabIconMap[indexTab].filledIcon
            : indexTabIconMap[indexTab].icon
        }
        label={label}
        value={indexTab}
      />
    );
  });

  return (
    <Tabs
      ref={tabsRef}
      centered
      className={classes.tabs}
      indicatorColor="primary"
      onChange={onChangeTypeTab}
      TabIndicatorProps={{
        className: classnames(indexTabIconMap[chosenIndexTab].selectedClass, classes.tabIndicator),
        id: 'ActiveTabIndicator',
      }}
      textColor="inherit"
      value={chosenIndexTab}
      variant="fullWidth"
    >
      {indexTabs}
    </Tabs>
  );
};

interface IndexTabsProps {
  resetScroll: () => void;
}
