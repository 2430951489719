import { makeStyles } from '../../../material';

const baseResultListContainer = {
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
};

export const useStyles = (props?: ResultsListContainerStylesProps) =>
  makeStyles(
    {
      documentPreviewContainer: {
        height: '100%',
      },
      documentsListContainer: {
        ...baseResultListContainer,
        borderRadius: props?.inSpotlight ? '8px 8px 0 0' : '0px',
      },
      documentsListInnerContainer: {
        height: '100%',
        overflowY: 'auto',
        paddingTop: '12px',
        width: '100%',
      },
      documentsListSubContainer: {
        height: 'calc(100% - 56px)',
      },
      indexTabsContainer: {
        alignItems: 'center',
        borderBottom: '1px solid #f4f4f4',
        display: 'flex',
        width: '100%',
      },
    },
    { index: 1 },
  )(props);

interface ResultsListContainerStylesProps {
  inSpotlight?: boolean;
}
